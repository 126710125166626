import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function ServiceLabor() {
    const [laborRO, setLaborRO] = useState(0);
    const [laborHours, setLaborHours] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(laborRO) / parseInt(laborHours)));
    }, [laborRO, laborHours])

    function clickResult() {
        history.push(
            {
                pathname: `/servicelaborresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        laborHours: numberValidation,
        laborRO: numberValidation
    });

    const def = <span>Service Labor Hours Billed &#247; Labor Repair Orders</span>;

    return (
        <Formik
            initialValues={{ laborRO: "", laborHours: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Service Labor Hours per RO</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="laborRO">
                                            <Form.Label>Service Labor Hours Billed ROs</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="laborRO" isInvalid={!!errors.laborRO}
                                                onChange={e => { handleChange(e); setLaborRO(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.laborRO}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="laborHours">
                                                <Form.Label>Total Service Labor Repair Orders</Form.Label>
                                                <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="laborHours" value={values.laborHours} isInvalid={!!errors.laborHours}
                                                    onChange={e => { handleChange(e); setLaborHours(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.laborHours}</Form.Control.Feedback>
                                                </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function ServiceLaborResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Service Labor Hours Per RO";
    const total = urlParams.get('total') + " Retail";
    const ratio = "6+ retail hours per RO";

    const cond = parseFloat(total) >= 6;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    ServiceLabor,
    ServiceLaborResult
}