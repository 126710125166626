import * as Yup from 'yup';

export const numberValidation = Yup.number()
    //.typeError('Please enter a number')
    .required('Required *');

export const monthValidation = Yup.number()
    .positive('Number must be positive')
    .typeError('Please enter a number')
    .max('12', 'Value must be less than or equal to 12');

//export default numberValidation