import React from 'react';
import '../../App.css';

export default function formulaDefinition(def) {
    return (
        <div className="card text-white rounded formula-definition">
            <div className="card-header text-center" id="formula">Formula Definition : {def}</div>
        </div>
    );
}

