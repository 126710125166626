import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PrescriptionAbsorption() {
    const [usedGross, setUsedGross] = useState(0);
    const [serviceGross, setServiceGross] = useState(0);
    const [paGross, setPAGross] = useState(0);
    const [bodyshopGross, setBodyShopGross] = useState(0);
    const [dealershipexpense, setDealershipExpense] = useState(0);
    const [lrExpense, setLRExpense] = useState(0);
    const [salesComp, setSalesComp] = useState(0);
    const [deliveryExpense, setDeliveryExpense] = useState(0);
    const [policyExpense, setPolicyExpense] = useState(0);

    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            ((parseInt(usedGross) + parseInt(serviceGross) + parseInt(paGross) + parseInt(bodyshopGross))
                /
                (parseInt(dealershipexpense) - (parseInt(lrExpense) + parseInt(salesComp) + parseInt(deliveryExpense) + parseInt(policyExpense)))) * 100
        );
    }, [usedGross, serviceGross, paGross, bodyshopGross, dealershipexpense, lrExpense, salesComp, deliveryExpense, policyExpense])

    function clickResult() {
        history.push(
            {
                pathname: `/prescriptionabsorptionresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        usedGross: numberValidation,
        serviceGross: numberValidation,
        paGross: numberValidation,
        bodyshopGross: numberValidation,
        dealershipexpense: numberValidation,
        lrExpense: numberValidation,
        salesComp: numberValidation,
        deliveryExpense: numberValidation,
        policyExpense: numberValidation
    });

    const def = <span>Total used-truck, service, parts & body shop gross profit ÷ total dealership expense, excluding lease & rental, minus new-sales commissions, delivery & policy expense.</span>;

    return (
        <Formik
            initialValues={{
                usedGross: "", serviceGross: "", paGross: "", bodyshopGross: "", dealershipexpense: "", lrExpense: "",
                salesComp: "", deliveryExpense: "", policyExpense: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Prescription Absorption</h2>
                                {formulaDefinition(def)}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="usedGross">
                                            <Form.Label>Used Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedGross" isInvalid={!!errors.usedGross}
                                                    value={values.usedGross} onChange={e => { handleChange(e); setUsedGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.usedGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="serviceGross">
                                            <Form.Label>Service Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="serviceGross" isInvalid={!!errors.serviceGross}
                                                    value={values.serviceGross} onChange={e => { handleChange(e); setServiceGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.serviceGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="paGross">
                                            <Form.Label>P&A Gross </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="paGross" isInvalid={!!errors.paGross}
                                                    value={values.paGross} onChange={e => { handleChange(e); setPAGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.paGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="bodyshopGross">
                                            <Form.Label>Body Shop Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="bodyshopGross" isInvalid={!!errors.bodyshopGross}
                                                    value={values.bodyshopGross} onChange={e => { handleChange(e); setBodyShopGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.bodyshopGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="dealershipexpense">
                                            <Form.Label>Total Dealership Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="dealershipexpense" isInvalid={!!errors.dealershipexpense}
                                                    value={values.dealershipexpense} onChange={e => { handleChange(e); setDealershipExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.dealershipexpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="lrExpense">
                                            <Form.Label>Lease & Rental Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="lrExpense" isInvalid={!!errors.lrExpense}
                                                    value={values.lrExpense} onChange={e => { handleChange(e); setLRExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.lrExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="salesComp">
                                            <Form.Label>New Vehicle Sales Commissions</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="salesComp" isInvalid={!!errors.salesComp}
                                                    value={values.salesComp} onChange={e => { handleChange(e); setSalesComp(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.salesComp}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="deliveryExpense">
                                            <Form.Label>New Vehicle Delivery Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="deliveryExpense" isInvalid={!!errors.deliveryExpense}
                                                    value={values.deliveryExpense} onChange={e => { handleChange(e); setDeliveryExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.deliveryExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="policyExpense">
                                            <Form.Label>New Vehicle Policy Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="policyExpense" isInvalid={!!errors.policyExpense}
                                                    value={values.policyExpense} onChange={e => { handleChange(e); setPolicyExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.policyExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PrescriptionAbsorptionResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Prescription Absorption";
    const total = urlParams.get('total') + "%";
    const ratio = <h5>130% <br/> (% of expense absorbed by these departments)</h5>;
    const cond = parseFloat(total) >= 130.00;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PrescriptionAbsorption,
    PrescriptionAbsorptionResult
}