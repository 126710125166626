import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PartsInventoryOver12Month() {
    const [parts12over, setParts12Over] = useState(0);
    const [partsinventory, setPartsInventory] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(parts12over) / parseInt(partsinventory)) * 100);
    }, [parts12over, partsinventory])

    function clickResult() {
        history.push(
            {
                pathname: `/partsinventoryover12monthresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        parts12over: numberValidation,
        partsinventory: numberValidation
    });

    const def = <span>Parts Inventory Over 12 Month &#247; Total Parts Inventory</span>;

    return (
        <Formik
            initialValues={{ parts12over: "", partsinventory: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Parts Inventory Over 12 Month</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="parts12over">
                                            <Form.Label>Parts Inventory Over 12 Month</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="parts12over" isInvalid={!!errors.parts12over}
                                                    value={values.parts12over} onChange={e => { handleChange(e); setParts12Over(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.parts12over}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsinventory">
                                            <Form.Label>Total Parts Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="partsinventory" isInvalid={!!errors.partsinventory}
                                                value={values.partsinventory} onChange={e => { handleChange(e); setPartsInventory(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.partsinventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PartsInventoryOver12MonthResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Parts Inventory Over 12 Month";
    const total = urlParams.get('total') + '%';
    const ratio = <h5>3% (or 5 % if OEM has {">"} 12 month stocking requirement)</h5>;

    const cond = parseFloat(total) <= 3;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PartsInventoryOver12Month,
    PartsInventoryOver12MonthResult
}