import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function TechProficiency() {
    const [labor, setLabor] = useState(0);
    const [hours, setHours] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(labor) / parseInt(hours)) * 100);
    }, [labor, hours])

    function clickResult() {
        history.push(
            {
                pathname: `/techproficiencyresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        labor: numberValidation,
        hours: numberValidation
    });

    const def = <span>Service Labor Hours Billed &#247; Service Hours Available</span>;

    return (
        <Formik
            initialValues={{ labor: "", hours: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Tech Proficiency</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="labor">
                                            <Form.Label>Service Labor Hours Billed</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="labor" value={values.labor} isInvalid={!!errors.labor}
                                                onChange={e => { handleChange(e); setLabor(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.labor}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="hours">
                                            <Form.Label>Service Hours Available</Form.Label>
                                                <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="hours" isInvalid={!!errors.hours}
                                                onChange={e => { handleChange(e); setHours(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function TechProficiencyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Tech Proficiency";
    const total = urlParams.get('total') + '%';
    const ratio = "100%";

    const cond = parseFloat(total) >= 100;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    TechProficiency,
    TechProficiencyResult
}