import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function GrossPerTechPerMonth() {
    const [servicelabour, setServiceLabour] = useState(0);
    const [months, setMonths] = useState(0);
    const [technicians, setTechnicians] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                ((parseInt(servicelabour) / parseInt(technicians)) / 100) 
            );
        }
        else {
            setTotal(
                (((parseInt(servicelabour) / parseInt(months)) * 12) / parseInt(technicians) / 100)
            );
        }
    }, [servicelabour, months, technicians])  
    function clickResult() {
        history.push(
            {
                pathname: `/grosspertechpermonthresult?total=${total.toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        servicelabour: numberValidation,
        months: monthValidation,
        technicians: numberValidation
    });

    const def = <span>Service Labor Gross per Month &#247; # of Technicians) &#247; 100.00</span>;

    return (
        <Formik
            initialValues={{ servicelabour: "", months: "", technicians: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Gross Per Tech Per Month at $100 Dollar Rate*</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="servicelabour">
                                            <Form.Label>Service Labor Gross per Month</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="servicelabour" value={values.servicelabour} isInvalid={!!errors.servicelabour}
                                                onChange={e => { handleChange(e); setServiceLabour(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.servicelabour}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="technicians">
                                            <Form.Label># of Technicians</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="technicians" value={values.technicians} isInvalid={!!errors.technicians}
                                                    onChange={e => { handleChange(e); setTechnicians(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.technicians}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function GrossPerTechPerMonthResult() {

    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Gross Per Tech Per Month at $100 Dollar Rate*";
    const totalInt = urlParams.get('total');
    const total = '$' + priceSplitter(totalInt);
    const ratio = "$11,200";

    const cond = parseFloat(totalInt) >= 112000;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    GrossPerTechPerMonth,
    GrossPerTechPerMonthResult
}