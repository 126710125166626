import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
                  <div className="version-number">
                      Version: 1.0.1
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
