import React from 'react';
import '../../App.css';
import BackButton from '../_Shared/BackButton';


export default function result(title, totalRE, ratio, cond) {

    const test = cond;

    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">{title}</h2>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="card h-100 text-white mb-3 answer-card">
                                <div className={ test ? 'answer-card-green' : 'answer-card-red'}>
                                    <div className="card-header card-results text-center">Your Data</div>
                                <div className="card-body">
                                    <h1 className="card-title text-center">{totalRE}</h1>
                                    <br />
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="card h-100 text-white bg-dark mb-3 ratio-card">
                                <div className="card-header card-results text-center">ATD Guide</div>
                                <div className="card-body">
                                    <h1 className="card-title text-center"> {ratio} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

