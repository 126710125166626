import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NewTruckInventoryTurns() {
    const [newSales, setNewSales] = useState(0);
    const [newGross, setNewGross] = useState(0);
    const [months, setMonths] = useState(0);
    const [newInventory, setNewInventory] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                ((parseInt(newSales) - parseInt(newGross)) / parseInt(newInventory))
            );
        }
        else {
            setTotal(
                ((((parseInt(newSales) - parseInt(newGross)) / parseInt(months)) * 12) / parseInt(newInventory))
            );
        }
    }, [newSales, newGross, months, newInventory])

    function clickResult() {
        history.push(
            {
                pathname: `/newtruckinventoryturnsresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        newSales: numberValidation,
        newGross: numberValidation,
        months: monthValidation,
        newInventory: numberValidation
    });

    const def = <span>(New Truck Dept Cost of Sales per Month) &#247; New Truck Sales</span>;

    return (
        <Formik
            initialValues={{ newSales: "", newGross:"", months:"", newInventory: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">New Truck Inventory Turns</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newSales">
                                            <Form.Label>New Truck Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newSales" value={values.newSales} isInvalid={!!errors.newSales}
                                                onChange={e => { handleChange(e); setNewSales(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newSales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newGross">
                                            <Form.Label>New Truck Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newGross" value={values.newGross} isInvalid={!!errors.newGross}
                                                    onChange={e => { handleChange(e); setNewGross(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newInventory">
                                            <Form.Label>New Truck Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newInventory" value={values.newInventory} isInvalid={!!errors.newInventory}
                                                    onChange={e => { handleChange(e); setNewInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NewTruckInventoryTurnsResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "New Truck Inventory Turns";
    const total = urlParams.get('total');
    const ratio = "6 times per year";

    const cond = parseFloat(total) <= 6;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    NewTruckInventoryTurns,
    NewTruckInventoryTurnsResult
}