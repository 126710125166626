import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function WarrantyReceivables() {
    const [warranty, setWarranty] = useState(0);
    const [sales, setSales] = useState(0);
    const [service, setService] = useState(0);
    const [bodyshop, setBodyShop] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            (parseInt(warranty) / ((parseInt(sales) + parseInt(service) + parseInt(bodyshop)) / (parseInt(months) * 12)))
        );
    }, [warranty, sales, service, bodyshop, months])

    function clickResult() {
        history.push(
            {
                pathname: `/warrantyreceivablesresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        warranty: numberValidation,
        sales: numberValidation,
        service: numberValidation,
        bodyshop: numberValidation,
        months: monthValidation
    });

    const def = <span>Warranty Receivables &#247; ((Service Warranty Sales + P&A Warranty Sales + Body Shop Warranty Sales) per Month)</span>;

    return (
        <Formik
            initialValues={{ warranty: "", sales: "", service:"", bodyShop:"", months:"" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Warranty Receivables % Parts & Labor Sales</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="warranty">
                                            <Form.Label>Warranty Receivables</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="warranty" value={values.warranty} isInvalid={!!errors.warranty}
                                                onChange={e => { handleChange(e); setWarranty(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.warranty}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="sales">
                                            <Form.Label>Warranty Parts Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="sales" value={values.sales} isInvalid={!!errors.sales}
                                                onChange={e => { handleChange(e); setSales(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.sales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>


                                        <Form.Group as={Col} md="12" mb="3" controlId="service">
                                            <Form.Label>Service Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="service" value={values.service} isInvalid={!!errors.service}
                                                    onChange={e => { handleChange(e); setService(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.service}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>


                                        <Form.Group as={Col} md="12" mb="3" controlId="bodyshop">
                                            <Form.Label>Body Shop Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="bodyshop" value={values.bodyshop} isInvalid={!!errors.bodyshop}
                                                    onChange={e => { handleChange(e); setBodyShop(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.bodyshop}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>


                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function WarrantyReceivablesResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Warranty Receivables % Parts & Labor Sales";
    const total = urlParams.get('total') + '%';
    const ratio = "100%";

    const cond = parseFloat(total) <= 100;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    WarrantyReceivables,
    WarrantyReceivablesResult
}