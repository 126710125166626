import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PartsInventoryTurns() {
    const [partssales, setPartsSales] = useState(0);
    const [partsgross, setPartsGross] = useState(0);
    const [months, setMonths] = useState(0);
    const [partsinventory, setPartsInventory] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            ((parseInt(partssales) - parseInt(partsgross)) / parseInt(months) * 12) / parseInt(partsinventory)
        );
    }, [partssales, partsgross, months, partsinventory])


    function clickResult() {
        history.push(
            {
                pathname: `/partsinventoryturnsresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        partssales: numberValidation,
        partsgross: numberValidation,
        months: monthValidation,
        partsinventory: numberValidation
    });

    const def = <span>(Parts Sales - Parts Gross without adjustments per Months) x 12 &#247; Parts Inventory</span>;

    return (
        <Formik
            initialValues={{ partssales: "", partsgross: "", months: "", partsinventory: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Parts Inventory Turns</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partssales">
                                            <Form.Label>Parts Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="partssales" isInvalid={!!errors.partssales}
                                                value={values.partssales} onChange={e => { handleChange(e); setPartsSales(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.partssales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsgross">
                                            <Form.Label>Parts Gross without Adjustments</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="partsgross" isInvalid={!!errors.partsgross}
                                                    value={values.partsgross} onChange={e => { handleChange(e); setPartsGross(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.partsgross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsinventory">
                                            <Form.Label>Parts Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="partsinventory" isInvalid={!!errors.partsinventory}
                                                value={values.partsinventory} onChange={e => { handleChange(e); setPartsInventory(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.partsinventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PartsInventoryTurnsResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Parts Inventory Turns";
    const total = urlParams.get('total') + ' times per year';
    const ratio = "6 - 8 times per year";

    const cond = parseFloat(total) >= 6;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PartsInventoryTurns,
    PartsInventoryTurnsResult
}