import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function TotalAbsorption() {
    const [totalUsed, setTotalUsed] = useState(0);
    const [serive, setSerive] = useState(0);
    const [partsProfit, setPartsProfit] = useState(0);
    const [bodyShopProfit, setBodyShopProfit] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal(
            ((parseInt(totalUsed) + parseInt(serive) + parseInt(partsProfit) + parseInt(bodyShopProfit)) / (parseInt(expenses)) * 100)
        );
    }, [totalUsed, serive, partsProfit, bodyShopProfit, expenses])

    function clickResult() {
        history.push(
            {
                pathname: `/totalabsorptionresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        totalUsed: numberValidation,
        serive: numberValidation,
        partsProfit: numberValidation,
        bodyShopProfit: numberValidation,
        expenses: numberValidation
    });

    const def = <span>Total used-truck, service, parts and body shop gross profit ÷ total dealership expense, excluding lease & rental.</span>;

    return (
        <Formik
            initialValues={{ totalUsed: "", serive: "", partsProfit:"", bodyShopProfit:"", expenses:""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Total Absorption</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="totalUsed">
                                            <Form.Label>Total Used Vehicle Dept. Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="totalUsed" value={values.totalUsed} isInvalid={!!errors.totalUsed}
                                                onChange={e => { handleChange(e); setTotalUsed(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.totalUsed}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="serive">
                                            <Form.Label>Total Service Dept. Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="serive" value={values.serive} isInvalid={!!errors.serive}
                                                onChange={e => { handleChange(e); setSerive(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.serive}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsProfit">
                                            <Form.Label>Total P&A Dept Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="partsProfit" value={values.partsProfit} isInvalid={!!errors.partsProfit}
                                                onChange={e => { handleChange(e); setPartsProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.partsProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="bodyShopProfit">
                                            <Form.Label>Total Body Shop Dept Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="bodyShopProfit" value={values.bodyShopProfit} isInvalid={!!errors.bodyShopProfit}
                                                onChange={e => { handleChange(e); setBodyShopProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.bodyShopProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="expenses">
                                            <Form.Label>Total Dealership Expense less Lease & Rental Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="expenses" value={values.expenses} isInvalid={!!errors.expenses}
                                                onChange={e => { handleChange(e); setExpenses(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.expenses}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function TotalAbsorptionResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Total Absorption";
    const total = urlParams.get('total') + "%";
    const ratio = "130%";

    const cond = parseFloat(total) >= 130;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    TotalAbsorption,
    TotalAbsorptionResult
}