import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NewTruckInventoryDaysSupply() {
    const [newInventory, setNewInventory] = useState(0);
    const [newSold, setNewSold] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal(
            (parseInt(newInventory) / (parseInt(newSold) / parseInt(months))) * 30
        );
    }, [newInventory, newSold, months])

    function clickResult() {
        history.push(
            {
                pathname: `/newtruckinventorydayssupplyresult?total=${parseInt(total)}`
            })
    }

    const validationSchema = Yup.object({
        newInventory: numberValidation,
        newSold: numberValidation,
        months: monthValidation
    });

    const def = <span>(New Inventory Units ÷ (New Truck Unit Sold per Month)) x 30)</span>;

    return (
        <Formik
            initialValues={{ newInventory: "", newSold: "", months: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">New Truck Inventory Days Supply</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newInventory">
                                            <Form.Label>New Truck Inventory Units</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="newInventory" value={values.newInventory} isInvalid={!!errors.newInventory}
                                                onChange={e => { handleChange(e); setNewInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newSold">
                                            <Form.Label>New Trucks Sold</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newSold" value={values.newSold} isInvalid={!!errors.newSold}
                                                    onChange={e => { handleChange(e); setNewSold(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newSold}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NewTruckInventoryDaysSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "New Truck Inventory Days Supply";
    const total = urlParams.get('total') + " Days";
    const ratio = "60 Days";

    const cond = parseInt(total) <= 60;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    NewTruckInventoryDaysSupply,
    NewTruckInventoryDaysSupplyResult
}