import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function CurrentRatio() {
    const [assets, setAssets] = useState(0);
    const [lifo, setLifo] = useState(0);
    const [liabilities, setLiabilities] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(((parseInt(assets) + parseInt(lifo)) / (parseInt(liabilities))));
    }, [assets, lifo, liabilities])

    function clickResult() {
        history.push(
            {
                pathname: `/currentratioresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        assets: numberValidation,
        lifo: numberValidation,
        liabilities: numberValidation
    });

    const def = <span>Total current assets + LIFO (if included in current assets) ÷ total current liabilities. Defined as “the ability to pay bills.”</span>;

    return (
        <Formik
            initialValues={{ assets: "", lifo: "", liabilities: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Current Ratio (Current Assets to Current Liabilities)</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        
                                        <Form.Group as={Col} md="12" mb="3" controlId="assets">
                                            <Form.Label>Total Current Assets</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="assets" value={values.assets} isInvalid={!!errors.assets}
                                                onChange={e => { handleChange(e); setAssets(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.assets}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="lifo">
                                            <Form.Label>LIFO if included in Current Assets</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="lifo" value={values.lifo} isInvalid={!!errors.lifo}
                                                onChange={e => { handleChange(e); setLifo(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.lifo}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="liabilities">
                                            <Form.Label>Total Current Liabilities</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="liabilities" value={values.liabilities} isInvalid={!!errors.liabilities}
                                                onChange={e => { handleChange(e); setLiabilities(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.liabilities}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function CurrentRatioResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Current Ratio (Current Assets to Current Liabilities)";
    const total = urlParams.get('total');
    const ratio = "2.0-2.5 : 1 ";

    const cond = parseFloat(total) >= 2.0;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    CurrentRatio,
    CurrentRatioResult
}