
import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NetProfitReturnOnGross() {
    const [netProfit, setNetProfit] = useState(0);
    const [gross, setGross] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(netProfit) / parseInt(gross)) * 100);
    }, [netProfit, gross])

    function clickResult() {
        history.push(
            {
                pathname: `/netprofitreturnongrossresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        netProfit: numberValidation,
        gross: numberValidation
    });

    const def = <span>Total Dealership Gross Profit ÷ Total Dealership Net Profit before Tax  </span>;

    return (
        <Formik
            initialValues={{ netProfit: "", gross: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Net Profit Return On Gross</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="netProfit">
                                            <Form.Label>Total Dealership Net Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="netProfit" isInvalid={!!errors.netProfit}
                                                    value={values.netProfit} onChange={e => { handleChange(e); setNetProfit(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.netProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="gross">
                                            <Form.Label>Total Dealership Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="gross" isInvalid={!!errors.gross}
                                                    value={values.gross} onChange={e => { handleChange(e); setGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.gross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NetProfitReturnOnGrossResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Net Profit Return On Gross";
    const total = urlParams.get('total') + "%";
    const ratio = "20 - 25%";
   // const cond = parseFloat(total) >= 20;

    const cond = parseFloat(total) >= 20;
    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    NetProfitReturnOnGross,
    NetProfitReturnOnGrossResult
}