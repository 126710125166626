import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function CashDaysSupply() {
    const [cash, setCash] = useState(0);
    const [contracts, setContracts] = useState(0);
    const [securities, setSecurities] = useState(0);
    const [receivables, setReceivables] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [labor, setLabor] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == '') {
            setTotal(
                (((parseInt(cash) + parseInt(contracts) + parseInt(securities) + parseInt(receivables))
                    / (parseInt(expenses) + parseInt(labor))) * 30)
            );
        }
        else {
        setTotal(
            (
                ((parseInt(cash) + parseInt(contracts) + parseInt(securities) + parseInt(receivables))
                / ((parseInt(expenses) + parseInt(labor)) / parseInt(months))) * 30)
        );
        }
    }, [cash, contracts, securities, receivables, expenses, labor, months])

    function clickResult() {
        history.push(
            {
                pathname: `/cashdayssupplyresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        cash: numberValidation,
        contracts: numberValidation,
        securities: numberValidation,
        receivables: numberValidation,
        expenses: numberValidation,
        labor: numberValidation,
        months: monthValidation
    });

    const def = <span>Cash, contracts in transit, marketable securities and vehicle receivables ÷ total dealership expense (average month) x 30 days. </span>;

    return (
        <Formik
            initialValues={{ cash: "", contracts: "", securities: "", receivables: "", expenses: "", labor: "", months:"" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Cash Days Supply</h2>

                                {formulaDefinition(def)}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="cash">
                                            <Form.Label>Cash</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="cash" value={values.cash} isInvalid={!!errors.cash}
                                                onChange={e => { handleChange(e); setCash(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.cash}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="contracts">
                                            <Form.Label>Contracts in Transit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="contracts" value={values.contracts} isInvalid={!!errors.contracts}
                                                onChange={e => { handleChange(e); setContracts(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.contracts}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="securities">
                                            <Form.Label>Marketable Securities</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="securities" value={values.securities} isInvalid={!!errors.securities}
                                                onChange={e => { handleChange(e); setSecurities(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.securities}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="receivables">
                                            <Form.Label>Vehicle Receivables</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="receivables" value={values.receivables} isInvalid={!!errors.receivables}
                                                onChange={e => { handleChange(e); setReceivables(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.receivables}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="expenses">
                                            <Form.Label>Total Dealership Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="expenses"
                                                    value={values.expenses} isInvalid={!!errors.expenses}
                                                onChange={e => { handleChange(e); setExpenses(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.expenses}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="labor">
                                            <Form.Label>Technicians’ Cost of Labor per Day</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="labor" value={values.labor} isInvalid={!!errors.labor}
                                                onChange={e => { handleChange(e); setLabor(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.labor}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months of Expense $ Entered Above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function CashDaysSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Cash Days Supply";
    const total = urlParams.get('total') + " Days";
    const ratio = <div>1 month<br /><h2>(3 Months recommended)</h2></div>;
    const cond = parseFloat(total) >= 30;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    CashDaysSupply,
    CashDaysSupplyResult
}