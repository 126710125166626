import React  from 'react';
import BackButton from '../_Shared/BackButton';




function OpenRepairOrders() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Open Repair Orders</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">Formula Definition: Mechanical or body shop repair orders not yet invoiced.
                                    <br /><br/>
                                    Guide: Less than 2 days after last time punch 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    OpenRepairOrders
}