
import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from './_Shared/BackButton';
import { Collapse, Accordion, Card, Button } from 'react-bootstrap'

function Calculations() {

    return (

        <div className="container">
            <main role="main">
                <div className="jumbotron ">
                    <div className="col-sm-10 mx-auto">
                       <h2 className="text-center">
                            <BackButton />KPIs with Guides and Comparisons</h2>

                        <Accordion>
                        {/*----DEALERSHIP VITALS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="0">
                                    <h5>Dealership Vitals &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/assetutilization"><button type="button" className="list-group-item list-group-item-action calculation-list calculation-list">Asset Utilization</button></Link>
                                            <Link to="/cashdayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Cash Days Supply</button></Link>
                                            <Link to="/cashinbank"><button type="button" className="list-group-item list-group-item-action calculation-list">Cash in Bank</button></Link>
                                            <Link to="/currentratio"><button type="button" className="list-group-item list-group-item-action calculation-list">Current Ratio (Current Assets to Current Liabilities)</button></Link>
                                            <Link to="/debttoequity"><button type="button" className="list-group-item list-group-item-action calculation-list">Debt to Equity</button></Link>
                                            <Link to="/frozencapital"><button type="button" className="list-group-item list-group-item-action calculation-list">Frozen Capital</button></Link>
                                            <Link to="/inventorytrustposition"><button type="button" className="list-group-item list-group-item-action calculation-list">Inventory Trust Position</button></Link>
                                            <Link to="/lifo"><button type="button" className="list-group-item list-group-item-action calculation-list">LIFO (Last In, First Out)</button></Link>
                                            <Link to="/netprofitreturnonsales"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return on Sales</button></Link>
                                            <Link to="/netprofitreturnongross"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return On Gross</button></Link>
                                            <Link to="/netprofitreturnonassets"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return on Assets</button></Link>
                                            <Link to="/networth"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Worth</button></Link>
                                            <Link to="/returnonequity"><button type="button" className="list-group-item list-group-item-action calculation-list">Return On Equity</button></Link>
                                            <Link to="/workingcapital"><button type="button" className="list-group-item list-group-item-action calculation-list">Working Capital</button></Link>
                                            <Link to="/variableexpense"><button type="button" className="list-group-item list-group-item-action calculation-list">Variable Expense</button></Link>
                                            <Link to="/prescriptionabsorption"><button type="button" className="list-group-item list-group-item-action calculation-list"> Prescription Absorption </button></Link>
                                            <Link to="/usedtruckdaysdupplyindollars"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Truck Days Supply in Dollars</button></Link>
                                            <Link to="/partsinventorymonthssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Inventory Months Supply </button></Link>
                                           <Link to="/partsobsolescence"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Obsolescence</button></Link>
                                            <Link to="/totalabsorption"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Absorption</button></Link>
                                            <Link to="/fixedabsorption"><button type="button" className="list-group-item list-group-item-action calculation-list">Fixed Absorption</button></Link>
                                            <Link to="/openrepairorders"><button type="button" className="list-group-item list-group-item-action calculation-list">Open Repair Orders</button></Link>
                                            <Link to="/servicedepartmentproficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Service Department Proficiency</button></Link>
                                            <Link to="/policyandgoodwillpercentageofgross"><button type="button" className="list-group-item list-group-item-action calculation-list">Policy and Goodwill Percentage of Gross </button></Link>
                                            <Link to="/workinprocess"><button type="button" className="list-group-item list-group-item-action calculation-list">Work In Process</button></Link>
                                          
            </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----NEW AND USED VEHICLE DEPARTMENT----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="1">
                                    <h5>New and Used Vehicle Department &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/newtruckunitssoldpermonthpersalesperson"><button type="button" className="list-group-item list-group-item-action calculation-list">New Truck Units Sold per Month per Salesperson</button></Link>
                                            <Link to="/usedtruckunitssoldpermonthpersalesperson"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Truck Units Sold per Month per Salesperson</button></Link>
                                            <Link to="/newtruckinventorydayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">New Truck Inventory Days Supply</button></Link>
                                            <Link to="/usedtruckinventorydayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Truck Inventory Days Supply</button></Link>
                                            <Link to="/newtruckinventoryturns"><button type="button" className="list-group-item list-group-item-action calculation-list">New Truck Inventory Turns</button></Link>
                                            <Link to="/usedtruckinventoryturns"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Truck Inventory Turns</button></Link>
                                            <Link to="/newtruckgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">New Truck Gross % Sales</button></Link>
                                            <Link to="/usedtruckgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Truck Gross % Sales</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----SERVICE----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="2">
                                    <h5>Service &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/techsupport"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech-to-Support Personnel Ratio</button></Link>
                                            <Link to="/techproficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech Proficiency</button></Link>
                                            <Link to="/techproductivity"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech Productivity</button></Link>
                                            <Link to="/techefficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech Efficiency</button></Link>
                                            <Link to="/servicelabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Service Labor Hours per RO</button></Link>
                                            <Link to="/grosspertechpermonth"><button type="button" className="list-group-item list-group-item-action calculation-list">Gross Per Tech Per Month at $100 Dollar Rate*</button></Link>
                                            <Link to="/stallutilization"><button type="button" className="list-group-item list-group-item-action calculation-list">Stall Utilization</button></Link>
                                            <Link to="/customerlabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Customer Labor Gross Retention</button></Link>
                                            <Link to="/internallabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Internal Labor Gross Retention</button></Link>
                                            <Link to="/warrantylabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Warranty Labor Gross Retention</button></Link>
                                            <Link to="/receivables30"><button type="button" className="list-group-item list-group-item-action calculation-list">SPB Receivables 0-30 Days % Total</button></Link>
                                            <Link to="/receivables60"><button type="button" className="list-group-item list-group-item-action calculation-list">SPB Receivables 31-60 Days % Total</button></Link>
                                            <Link to="/warrantyreceivables"><button type="button" className="list-group-item list-group-item-action calculation-list">Warranty Receivables % Parts & Labor Sales</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----PARTS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="3">
                                    <h5>Parts &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/partssalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Sales Per Parts Employee</button></Link>
                                            <Link to="/partscostofsalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Cost of Sales Per Parts Employee</button></Link>
                                            <Link to="/partsgrossperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Gross Per Parts Employee</button></Link>
                                            <Link to="/partsgrosspercentsales"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Gross Percent Sales</button></Link>
                                            <Link to="/partstolaborratio"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts To Labor Ratio</button></Link>
                                            <Link to="/partsinventoryturns"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Inventory Turns</button></Link>
                                           <Link to="/partsinventoryover12month"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Inventory Over 12 Month</button></Link>
                                            <Link to="/partsmonthssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Months Supply</button></Link>
                                            <Link to="/fillratefirsttime"><button type="button" className="list-group-item list-group-item-action calculation-list">Fill Rate First TIme</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            {/*----BODY SHOP----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="4">
                                    <h5>Body Shop &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/technicianefficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Technician Efficiency</button></Link>
                                            <Link to="/bodyshopgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Body Shop Gross % Sales</button></Link>
                                            <Link to="/bodyshoplaborgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Body Shop Labor Gross % Sales</button></Link>
                                            <Link to="/partsbodyshopgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Body RO Gross % Sales</button></Link>
                                            <Link to="/bodyshoppaintmaterialssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Paint & Materials Gross % Sales</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                </div>
            </main>
        </div>
    );
}


export default Calculations;