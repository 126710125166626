import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function FixedAbsorption() {
    const [serviceGross, setServiceGross] = useState(0);
    const [paGross, setPAGross] = useState(0);
    const [bodyshopGross, setBodyShopGross] = useState(0);
    const [dealershipexpense, setDealershipExpense] = useState(0);
    const [lrExpense, setLRExpense] = useState(0);
    const [newSalesComp, setNewSalesComp] = useState(0);
    const [newDeliveryExpense, setNewDeliveryExpense] = useState(0);
    const [newPolicyExpense, setNewPolicyExpense] = useState(0);
    const [usedSalesComp, setUsedSalesComp] = useState(0);
    const [usedDeliveryExpense, setUsedDeliveryExpense] = useState(0);
    const [usedPolicyExpense, setUsedPolicyExpense] = useState(0);

    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            ((parseInt(serviceGross) + parseInt(paGross) + parseInt(bodyshopGross))
                /
                (parseInt(dealershipexpense) - (parseInt(lrExpense) + parseInt(newSalesComp) + parseInt(newDeliveryExpense) + parseInt(newPolicyExpense) + parseInt(usedSalesComp) + parseInt(usedDeliveryExpense) + parseInt(usedPolicyExpense)))) * 100
        );
    }, [serviceGross, paGross, bodyshopGross, dealershipexpense, lrExpense, newSalesComp, newDeliveryExpense, newPolicyExpense, usedSalesComp, usedDeliveryExpense, usedPolicyExpense ])

    function clickResult() {
        history.push(
            {
                pathname: `/fixedabsorptionresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        serviceGross: numberValidation,
        paGross: numberValidation,
        bodyshopGross: numberValidation,
        dealershipexpense: numberValidation,
        lrExpense: numberValidation,
        newSalesComp: numberValidation,
        newDeliveryExpense: numberValidation,
        newPolicyExpense: numberValidation,
        usedSalesComp: numberValidation,
        usedDeliveryExpense: numberValidation,
        usedPolicyExpense: numberValidation

    });

    const def = <span>Total fixed gross profit &#247; total dealership expense, excluding lease & rental, minus new- & used-sales commissions, delivery & policy expense (% of expense absorbed by fixed ops). </span>;

    return (
        <Formik
            initialValues={{
                serviceGross: "", paGross: "", bodyshopGross: "", dealershipexpense: "", lrExpense: "",
                newSalesComp: "", newDeliveryExpense: "", newPolicyExpense: "", usedSalesComp: "", usedDeliveryExpense: "", usedPolicyExpense: ""
            }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Fixed Absorption</h2>
                                {formulaDefinition(def)}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                       
                                        <Form.Group as={Col} md="12" mb="3" controlId="serviceGross">
                                            <Form.Label>Service Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="serviceGross" isInvalid={!!errors.serviceGross}
                                                    value={values.serviceGross} onChange={e => { handleChange(e); setServiceGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.serviceGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="paGross">
                                            <Form.Label>P&A Gross </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="paGross" isInvalid={!!errors.paGross}
                                                    value={values.paGross} onChange={e => { handleChange(e); setPAGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.paGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="bodyshopGross">
                                            <Form.Label>Body Shop Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="bodyshopGross" isInvalid={!!errors.bodyshopGross}
                                                    value={values.bodyshopGross} onChange={e => { handleChange(e); setBodyShopGross(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.bodyshopGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="dealershipexpense">
                                            <Form.Label>Total Dealership Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="dealershipexpense" isInvalid={!!errors.dealershipexpense}
                                                    value={values.dealershipexpense} onChange={e => { handleChange(e); setDealershipExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.dealershipexpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="lrExpense">
                                            <Form.Label>Lease & Rental Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="lrExpense" isInvalid={!!errors.lrExpense}
                                                    value={values.lrExpense} onChange={e => { handleChange(e); setLRExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.lrExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newSalesComp">
                                            <Form.Label>New Vehicle Sales Commissions</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newSalesComp" isInvalid={!!errors.newSalesComp}
                                                    value={values.newSalesComp} onChange={e => { handleChange(e); setNewSalesComp(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.newSalesComp}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newDeliveryExpense">
                                            <Form.Label>New Vehicle Delivery Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newDeliveryExpense" isInvalid={!!errors.newDeliveryExpense}
                                                    value={values.newDeliveryExpense} onChange={e => { handleChange(e); setNewDeliveryExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.newDeliveryExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newPolicyExpense">
                                            <Form.Label>New Vehicle Policy Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newPolicyExpense" isInvalid={!!errors.newPolicyExpense}
                                                    value={values.newPolicyExpense} onChange={e => { handleChange(e); setNewPolicyExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.newPolicyExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedSalesComp">
                                            <Form.Label>Used Vehicle Sales Commissions</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedSalesComp" isInvalid={!!errors.usedSalesComp}
                                                    value={values.usedSalesComp} onChange={e => { handleChange(e); setUsedSalesComp(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.usedSalesComp}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedDeliveryExpense">
                                            <Form.Label>Used Vehicle Delivery Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedDeliveryExpense" isInvalid={!!errors.usedDeliveryExpense}
                                                    value={values.usedDeliveryExpense} onChange={e => { handleChange(e); setUsedDeliveryExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.usedDeliveryExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedPolicyExpense">
                                            <Form.Label>Used Vehicle Policy Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedPolicyExpense" isInvalid={!!errors.usedPolicyExpense}
                                                    value={values.usedPolicyExpense} onChange={e => { handleChange(e); setUsedPolicyExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.usedPolicyExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function FixedAbsorptionResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Fixed Absorption";
    const total = urlParams.get('total')+ "%";
    const ratio = "115%";

    const cond = parseFloat(total) >= 115.00;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    FixedAbsorption,
    FixedAbsorptionResult
}