import React from 'react';
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button } from 'reactstrap';

function BackButton() {
    const history = useHistory();

    function clickBack() {
        history.goBack();
    }
    return (

        <Button onClick={clickBack} className="float-left d-flex align-items-center justify-content-center p-2 py-2 bg-secondary rounded">
            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-arrow-90deg-left" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
            </svg>
        </Button>
    );
}

export default withRouter(BackButton);

