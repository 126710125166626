import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PartsMonthsSupply() {
    const [partsInventory, setPartsInventory] = useState(0);
    const [totalParts, setTotalParts] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                parseInt(partsInventory) / (parseInt(totalParts) - (parseInt(grossProfit)))
            );
        }
        else {
            setTotal
                (
                    parseInt(partsInventory) / ((parseInt(totalParts) - (parseInt(grossProfit))) / parseInt(months))
                )
        }
    }, [partsInventory, totalParts, grossProfit, months])

    function clickResult() {
        history.push(
            {
                pathname: `/partsmonthssupplyresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        partsInventory: numberValidation,
        totalParts: numberValidation,
        grossProfit: numberValidation,
        months: monthValidation
    });

    const def = <span>Parts Inventory $ &#247; ((Total Parts Department Sales - (Total Parts Gross Profit excluding Discounts & Adjustments)) &#247; # of Months)</span>;

    return (
        <Formik
            initialValues={{ partsInventory: "", totalParts: "", grossProfit: "", months: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Parts Months Supply</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsInventory">
                                            <Form.Label>Parts Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="partsInventory" value={values.partsInventory} isInvalid={!!errors.partsInventory}
                                                onChange={e => { handleChange(e); setPartsInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.partsInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="totalParts">
                                            <Form.Label>Total Parts Department Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="totalParts" value={values.totalParts} isInvalid={!!errors.totalParts}
                                                    onChange={e => { handleChange(e); setTotalParts(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.totalParts}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="grossProfit">
                                            <Form.Label>Total Parts Gross Profit excluding Discounts and Adjustments</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="grossProfit" value={values.grossProfit} isInvalid={!!errors.grossProfit}
                                                onChange={e => { handleChange(e); setGrossProfit(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.grossProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PartsMonthsSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Parts Months Supply";
    const total = urlParams.get('total') + " Months";
    const ratio = "1.5 Months";

    const cond = parseFloat(total) <= 1.5;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PartsMonthsSupply,
    PartsMonthsSupplyResult
}