import React, { useState, useEffect } from "react";
import BackButton from "../_Shared/BackButton";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { numberValidation, monthValidation } from "../_Shared/Validation";
import result from "../_Shared/Result";
import formulaDefinition from "../_Shared/FormulaDefinition";

function PolicyandGoodwillPercentageofGross() {
    const [policyExpense, setPolicyExpense] = useState(0);
    const [grossProfits, SetGrossProfit] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(((parseInt(policyExpense)*100) / parseInt(grossProfits)));
    }, [policyExpense, grossProfits]);

    function clickResult() {
        history.push({
            pathname: `/policyandgoodwillpercentageofgrossresult?total=${parseFloat(total).toFixed(2)}`,
        });
    }

    const validationSchema = Yup.object({
        policyExpense: numberValidation,
        grossProfits: numberValidation,
    });

    const def = <span>Goodwill granted to a service department customer &#247; service department gross profit.</span>;


    return (
        <Formik
            initialValues={{ policyExpense: "", grossProfits: "" }}
            validationSchema={validationSchema}
            onSubmit={(e) => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Policy and Goodwill Precentage of Gross</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="policyExpense">
                                            <Form.Label>Service Policy Expense</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="policyExpense" isInvalid={!!errors.policyExpense}
                                                    value={values.policyExpense} onChange={e => { handleChange(e); setPolicyExpense(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.policyExpense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="grossProfits">
                                            <Form.Label>Service Department Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="grossProfits" isInvalid={!!errors.grossProfits}
                                                    value={values.grossProfits} onChange={e => { handleChange(e); SetGrossProfit(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.grossProfits}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );
}

function PolicyandGoodwillPercentageofGrossResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Policy and Goodwill Percentage of Gross";
    const total = urlParams.get("total") + "%";
    const ratio = <h5>2% of service department gross profit</h5>;
    const cond = parseFloat(total) >= 2;

    return <div>{result(title, total, ratio, cond)}</div>;
}

export {
    PolicyandGoodwillPercentageofGross,
    PolicyandGoodwillPercentageofGrossResult,
};
