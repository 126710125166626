import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PartsInventoryMonthsSupply() {
    const [dollaramount, setdollaramount] = useState(0);
    const [partssales, setpartssales] = useState(0);
    const [partsGross, setpartsGross] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();
    useEffect(() => {
        setTotal(
            (parseInt(dollaramount) / (parseInt(partssales) - parseInt(partsGross)))
        );
    }, [dollaramount, partssales, partsGross])

    function clickResult() {
        history.push(
            {
                pathname: `/partsinventorymonthssupplyresult?total=${parseFloat(total).toFixed(2)}`
            })
    }
    const validationSchema = Yup.object({
        dollaramount: numberValidation,
        partssales: numberValidation,
        partsGross: numberValidation
    });
    const def = <span>Reconciled inventory amount ÷ the average month cost of sales.  </span>;
    return (
        <Formik
            initialValues={{ dollaramount: "", partssales: "", partsGross: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Parts Inventory Months’ Supply </h2>
                                {formulaDefinition(def)}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="dollaramount">
                                            <Form.Label>Parts Inventory Dollar Amount</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="dollaramount" value={values.dollaramount} isInvalid={!!errors.dollaramount}
                                                    onChange={e => { handleChange(e); setdollaramount(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.dollaramount}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partssales">
                                            <Form.Label>Parts Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="partssales" value={values.partssales} isInvalid={!!errors.partssales}
                                                    onChange={e => { handleChange(e); setpartssales(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.partssales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="partsGross">
                                            <Form.Label>Parts Gross without adjustments</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="partsGross" value={values.partsGross} isInvalid={!!errors.partsGross}
                                                    onChange={e => { handleChange(e); setpartsGross(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.partsGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>                                       

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PartsInventoryMonthsSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Parts Inventory";
    const total = urlParams.get('total');
    const ratio = " 1.5-2 months’ supply";
    const cond = parseFloat(total) >= 1.5 && parseFloat(total) <= 2;
    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PartsInventoryMonthsSupply,
    PartsInventoryMonthsSupplyResult
}


