import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import { NavMenu } from './components/_Shared/NavMenu';
import Footer from './components/_Shared/Footer';
import Calculations from './components/Calculations';

//Dealership Vitals
import { AssetUtilization, AssetUtilizationResult } from './components/DealershipVitals/AssetUtilization'
import { CashDaysSupply, CashDaysSupplyResult } from './components/DealershipVitals/CashDaysSupply';
import { CashInBank, CashInBankResult } from './components/DealershipVitals/CashInBank';
import { CurrentRatio, CurrentRatioResult } from './components/DealershipVitals/CurrentRatio';
import { DebtToEquity, DebtToEquityResult } from './components/DealershipVitals/DebtToEquity';
import { FrozenCapital } from './components/DealershipVitals/FrozenCapital';
import { InventoryTrustPosition } from './components/DealershipVitals/InventoryTrustPosition';
import { LIFO } from './components/DealershipVitals/LIFO';
import { NetProfitReturnOnSales, NetProfitReturnOnSalesResult } from './components/DealershipVitals/NetProfitReturnOnSales';
import { NetProfitReturnOnGross, NetProfitReturnOnGrossResult } from './components/DealershipVitals/NetProfitReturnOnGross';
import { NetProfitReturnOnAssets, NetProfitReturnOnAssetsResult } from './components/DealershipVitals/NetProfitReturnOnAssets';
import { NetWorth } from './components/DealershipVitals/NetWorth';
import { ReturnOnEquity, ReturnOnEquityResult } from './components/DealershipVitals/ReturnOnEquity';
import { WorkingCapital } from './components/DealershipVitals/WorkingCapital';
import { VariableExpense } from './components/DealershipVitals/VariableExpense';
import { PrescriptionAbsorption, PrescriptionAbsorptionResult } from './components/DealershipVitals/PrescriptionAbsorption';
import { UsedTruckDaysSupplyInDollars, UsedTruckDaysSupplyInDollarsResult } from './components/DealershipVitals/UsedTruckDaysSupplyInDollars';
import { PartsInventoryMonthsSupply, PartsInventoryMonthsSupplyResult } from './components/DealershipVitals/PartsInventoryMonthsSupply';
import { PartsObsolescence, PartsObsolescenceResult } from './components/DealershipVitals/PartsObsolescence';
import { TotalAbsorption, TotalAbsorptionResult } from './components/DealershipVitals/TotalAbsorption';
import { FixedAbsorption, FixedAbsorptionResult } from './components/DealershipVitals/FixedAbsorption';
import { OpenRepairOrders } from './components/DealershipVitals/OpenRepairOrders';
import { ServiceDepartmentProficiency, ServiceDepartmentProficiencyResult } from './components/DealershipVitals/ServiceDepartmentProficiency';
import { PolicyandGoodwillPercentageofGross, PolicyandGoodwillPercentageofGrossResult } from './components/DealershipVitals/PolicyandGoodwillPercentageofGross';
import { WorkinProcess } from './components/DealershipVitals/WorkinProcess';

//NEW AND USED VEHICLE DEPARTMENT
import { NewTruckUnitsSoldPerMonthperSalesperson, NewTruckUnitsSoldPerMonthperSalespersonResult } from './components/NewandUsedVehicleDepartment/NewTruckUnitsSoldPerMonthperSalesperson';
import { UsedTruckUnitsSoldPerMonthperSalesperson, UsedTruckUnitsSoldPerMonthperSalespersonResult } from './components/NewandUsedVehicleDepartment/UsedTruckUnitsSoldPerMonthperSalesperson';
import { NewTruckInventoryDaysSupply, NewTruckInventoryDaysSupplyResult } from './components/NewandUsedVehicleDepartment/NewTruckInventoryDaysSupply';
import { UsedTruckInventoryDaysSupply, UsedTruckInventoryDaysSupplyResult } from './components/NewandUsedVehicleDepartment/UsedTruckInventoryDaysSupply';
import { NewTruckInventoryTurns, NewTruckInventoryTurnsResult } from './components/NewandUsedVehicleDepartment/NewTruckInventoryTurns';
import { UsedTruckInventoryTurns, UsedTruckInventoryTurnsResult } from './components/NewandUsedVehicleDepartment/UsedTruckInventoryTurns';
import { NewTruckGrossSales, NewTruckGrossSalesResult } from './components/NewandUsedVehicleDepartment/NewTruckGrossSales';
import { UsedTruckGrossSalesy, UsedTruckGrossSalesyResult } from './components/NewandUsedVehicleDepartment/UsedTruckGrossSalesy';

//SERVICE
import { TechSupport, TechSupportResult } from './components/Service/TechSupport';
import { TechProficiency, TechProficiencyResult } from './components/Service/TechProficiency';
import { TechProductivity, TechProductivityResult } from './components/Service/TechProductivity';
import { TechEfficiency, TechEfficiencyResult } from './components/Service/TechEfficiency';
import { ServiceLabor, ServiceLaborResult } from './components/Service/ServiceLabor';
import { GrossPerTechPerMonth, GrossPerTechPerMonthResult } from './components/Service/GrossPerTechPerMonth';
import { StallUtilization, StallUtilizationResult } from './components/Service/StallUtilization';
import { CustomerLabor, CustomerLaborResult } from './components/Service/CustomerLabor';
import { InternalLabor, InternalLaborResult } from './components/Service/InternalLabor';
import { WarrantyLabor, WarrantyLaborResult } from './components/Service/WarrantyLabor';
import { Receivables30, Receivables30Result } from './components/Service/Receivables30';
import { Receivables60, Receivables60Result } from './components/Service/Receivables60';
import { WarrantyReceivables, WarrantyReceivablesResult } from './components/Service/WarrantyReceivables';

//PARTS
import { PartsSalesPerPartsEmployee, PartsSalesPerPartsEmployeeResult } from './components/Parts/PartsSalesPerPartsEmployee';
import { PartsCostofSalesPerPartsEmployee, PartsCostofSalesPerPartsEmployeeResult } from './components/Parts/PartsCostOfSalesPerPartsEmployee';
import { PartsGrossPerPartsEmployee, PartsGrossPerPartsEmployeeResult } from './components/Parts/PartsGrossPerPartsEmployee';
import { PartsGrossPercentSales, PartsGrossPercentSalesResult } from './components/Parts/PartsGrossPercentSales';
import { PartsToLaborRatio, PartsToLaborRatioResult } from './components/Parts/PartsToLaborRatio';
import { PartsInventoryTurns, PartsInventoryTurnsResult } from './components/Parts/PartsInventoryTurns';
import { PartsInventoryOver12Month, PartsInventoryOver12MonthResult } from './components/Parts/PartsInventoryOver12Month';
import { PartsMonthsSupply, PartsMonthsSupplyResult } from './components/Parts/PartsMonthsSupply';
import { FillRateFirstTime } from './components/Parts/FillRateFirstTime';

//BODY SHOP
import { TechnicianEfficiency, TechnicianEfficiencyResults } from './components/BodyShop/TechnicianEfficiency';
import { BodyShopGrossSales, BodyShopGrossSalesResults } from './components/BodyShop/BodyShopGrossSales';
import { BodyShopLaborGrossSales, BodyShopLaborGrossSalesResults } from './components/BodyShop/BodyShopLaborGrossSales';
import { PartsBodyShopGrossSales, PartsBodyShopGrossSalesResults } from './components/BodyShop/PartsBodyShopGrossSales';
import { BodyShopPaintMaterialsSales, BodyShopPaintMaterialsSalesResults } from './components/BodyShop/BodyShopPaintMaterialsSales';

function App() {
    return (
        <div className="page-container">
         <div className="content-wrap">
          <Router>
           <NavMenu />
            <Switch>
             <Route exact path='/' component={Home} />
             <Route path='/calculations' component={Calculations} />

             {/*----DEALERSHIPS VITALS----*/}
             <Route path='/assetutilization' component={AssetUtilization} />
             <Route path='/assetutilizationresult?total=:total' component={AssetUtilizationResult} />
             <Route path='/cashdayssupply' component={CashDaysSupply} />
             <Route path='/cashdayssupplyresult?total=:total' component={CashDaysSupplyResult} />
             <Route path='/cashinbank' component={CashInBank} />
             <Route path='/cashinbankresult?total=:total' component={CashInBankResult} />
             <Route path='/currentratio' component={CurrentRatio} />
             <Route path='/currentratioresult?total=:total' component={CurrentRatioResult} />
             <Route path='/debttoequity' component={DebtToEquity} />
             <Route path='/debttoequityresult?total=:total' component={DebtToEquityResult} />
             <Route path='/frozencapital' component={FrozenCapital} />
             <Route path='/inventorytrustposition' component={InventoryTrustPosition} />
             <Route path='/lifo' component={LIFO} />
             <Route path='/netprofitreturnonsales' component={NetProfitReturnOnSales} />
             <Route path='/netprofitreturnonsalesresult?total=:total' component={NetProfitReturnOnSalesResult} />
             <Route path='/netprofitreturnongross' component={NetProfitReturnOnGross} />
             <Route path='/netprofitreturnongrossresult?total=:total' component={NetProfitReturnOnGrossResult} />
             <Route path='/netprofitreturnonassets' component={NetProfitReturnOnAssets} />
             <Route path='/netprofitreturnonassetsresult?total=:total' component={NetProfitReturnOnAssetsResult} />
             <Route path='/networth' component={NetWorth} />
             <Route path='/returnonequity' component={ReturnOnEquity} />
             <Route path='/returnonequityresult?total=:total' component={ReturnOnEquityResult} />
             <Route path='/workingcapital' component={WorkingCapital} />
             <Route path='/variableexpense' component={VariableExpense} />
             <Route path='/prescriptionabsorption' component={PrescriptionAbsorption} />
             <Route path='/prescriptionabsorptionresult?total=:total' component={PrescriptionAbsorptionResult} />
             <Route path='/usedtruckdaysdupplyindollars' component={UsedTruckDaysSupplyInDollars} />
             <Route path='/usedtruckdaysdupplyindollarsresult?total=:total' component={UsedTruckDaysSupplyInDollarsResult} />
             <Route path='/partsinventorymonthssupply' component={PartsInventoryMonthsSupply} />
             <Route path='/partsinventorymonthssupplyresult?total=:total' component={PartsInventoryMonthsSupplyResult} />
             <Route path='/partsobsolescence' component={PartsObsolescence} />
             <Route path='/partsobsolescenceresult?total=:total' component={PartsObsolescenceResult} />
             <Route path='/totalabsorption' component={TotalAbsorption} />
             <Route path='/totalabsorptionresult?total=:total' component={TotalAbsorptionResult} />
             <Route path='/fixedabsorption' component={FixedAbsorption} />
             <Route path='/fixedabsorptionresult?total=:total' component={FixedAbsorptionResult} />
             <Route path='/openrepairorders' component={OpenRepairOrders} />
             <Route path='/servicedepartmentproficiency' component={ServiceDepartmentProficiency} />
             <Route path='/servicedepartmentproficiencyresult?total=:total' component={ServiceDepartmentProficiencyResult} />
             <Route path='/policyandgoodwillpercentageofgross' component={PolicyandGoodwillPercentageofGross} />
             <Route path='/policyandgoodwillpercentageofgrossresult?total=:total' component={PolicyandGoodwillPercentageofGrossResult} />
             <Route path='/workinprocess' component={WorkinProcess} />

             {/*----NEW AND USED VEHICLE DEPARTMENT----*/}
             <Route path='/newtruckunitssoldpermonthpersalesperson' component={NewTruckUnitsSoldPerMonthperSalesperson} />
             <Route path='/newtruckunitssoldpermonthpersalespersonresult?total=:total' component={NewTruckUnitsSoldPerMonthperSalespersonResult} />
             <Route path='/usedtruckunitssoldpermonthpersalesperson' component={UsedTruckUnitsSoldPerMonthperSalesperson} />
             <Route path='/usedtruckunitssoldpermonthpersalespersonresult?total=:total' component={UsedTruckUnitsSoldPerMonthperSalespersonResult} />
             <Route path='/newtruckinventorydayssupply' component={NewTruckInventoryDaysSupply} />
             <Route path='/newtruckinventorydayssupplyresult?total=:total' component={NewTruckInventoryDaysSupplyResult} />
             <Route path='/usedtruckinventorydayssupply' component={UsedTruckInventoryDaysSupply} />
             <Route path='/usedtruckinventorydayssupplyresult?total=:total' component={UsedTruckInventoryDaysSupplyResult} />
             <Route path='/newtruckinventoryturns' component={NewTruckInventoryTurns} />
             <Route path='/newtruckinventoryturnsresult?total=:total' component={NewTruckInventoryTurnsResult} />
             <Route path='/usedtruckinventoryturns' component={UsedTruckInventoryTurns} />
             <Route path='/usedtruckinventoryturnsresult?total=:total' component={UsedTruckInventoryTurnsResult} />
             <Route path='/newtruckgrosssales' component={NewTruckGrossSales} />
             <Route path='/newtruckgrosssalesresult?total=:total' component={NewTruckGrossSalesResult} />
             <Route path='/usedtruckgrosssales' component={UsedTruckGrossSalesy} />
             <Route path='/usedtruckgrosssalesresult?total=:total' component={UsedTruckGrossSalesyResult} />

             {/*----SERVICE----*/}
             <Route path='/techsupport' component={TechSupport} />
             <Route path='/techsupportresult?total=:total' component={TechSupportResult} />
             <Route path='/techproficiency' component={TechProficiency} />
             <Route path='/techproficiencyresult?total=:total' component={TechProficiencyResult} />
             <Route path='/techproductivity' component={TechProductivity} />
             <Route path='/techproductivityresult?total=:total' component={TechProductivityResult} />
             <Route path='/techefficiency' component={TechEfficiency} />
             <Route path='/techefficiencyresult?total=:total' component={TechEfficiencyResult} />
             <Route path='/servicelabor' component={ServiceLabor} />
             <Route path='/servicelaborresult?total=:total' component={ServiceLaborResult} />
             <Route path='/grosspertechpermonth' component={GrossPerTechPerMonth} />
             <Route path='/grosspertechpermonthresult?total=:total' component={GrossPerTechPerMonthResult} />
             <Route path='/stallutilization' component={StallUtilization} />
             <Route path='/stallutilizationresult?total=:total' component={StallUtilizationResult} />
             <Route path='/customerlabor' component={CustomerLabor} />
             <Route path='/customerlaborresult?total=:total' component={CustomerLaborResult} />
             <Route path='/internallabor' component={InternalLabor} />
             <Route path='/internallaborresult?total=:total' component={InternalLaborResult} />
             <Route path='/warrantylabor' component={WarrantyLabor} />
             <Route path='/warrantylaborresult?total=:total' component={WarrantyLaborResult} />
             <Route path='/receivables30' component={Receivables30} />
             <Route path='/receivables30result?total=:total' component={Receivables30Result} />
             <Route path='/receivables60' component={Receivables60} />
             <Route path='/receivables60result?total=:total' component={Receivables60Result} />
             <Route path='/warrantyreceivables' component={WarrantyReceivables} />
             <Route path='/warrantyreceivablesresult?total=:total' component={WarrantyReceivablesResult} />

             {/*----PARTS----*/}
             <Route path='/partssalesperpartsemployee' component={PartsSalesPerPartsEmployee} />
             <Route path='/partssalesperpartsemployeeresult?total=:total' component={PartsSalesPerPartsEmployeeResult} />
             <Route path='/partscostofsalesperpartsemployee' component={PartsCostofSalesPerPartsEmployee} />
             <Route path='/partscostofsalesperpartsemployeeresult?total=:total' component={PartsCostofSalesPerPartsEmployeeResult} />
             <Route path='/partsgrossperpartsemployee' component={PartsGrossPerPartsEmployee} />
             <Route path='/partsgrossperpartsemployeeresult?total=:total' component={PartsGrossPerPartsEmployeeResult} />
             <Route path='/partsgrosspercentsales' component={PartsGrossPercentSales} />
             <Route path='/partsgrosspercentsalesresult?total=:total' component={PartsGrossPercentSalesResult} />
             <Route path='/partstolaborratio' component={PartsToLaborRatio} />
             <Route path='/partstolaborratioresult?total=:total' component={PartsToLaborRatioResult} />
             <Route path='/partsinventoryturns' component={PartsInventoryTurns} />
             <Route path='/partsinventoryturnsresult?total=:total' component={PartsInventoryTurnsResult} />
             <Route path='/partsinventoryover12month' component={PartsInventoryOver12Month} />
             <Route path='/partsinventoryover12monthresult?total=:total' component={PartsInventoryOver12MonthResult} />
             <Route path='/partsmonthssupply' component={PartsMonthsSupply} />
             <Route path='/partsmonthssupplyresult?total=:total' component={PartsMonthsSupplyResult} />
             <Route path='/fillratefirsttime' component={FillRateFirstTime} />

             {/*----BODY SHOP----*/}
             <Route path='/technicianefficiency' component={TechnicianEfficiency} />
             <Route path='/technicianefficiencyresult?total=:total' component={TechnicianEfficiencyResults} />
             <Route path='/bodyshopgrosssales' component={BodyShopGrossSales} />
             <Route path='/bodyshopgrosssalesresult?total=:total' component={BodyShopGrossSalesResults} />
             <Route path='/bodyshoplaborgrosssales' component={BodyShopLaborGrossSales} />
             <Route path='/bodyshoplaborgrosssalesresult?total=:total' component={BodyShopLaborGrossSalesResults} />
             <Route path='/partsbodyshopgrosssales' component={PartsBodyShopGrossSales} />
             <Route path='/partsbodyshopgrosssalesresult?total=:total' component={PartsBodyShopGrossSalesResults} />
             <Route path='/bodyshoppaintmaterialssales' component={BodyShopPaintMaterialsSales} />
             <Route path='/bodyshoppaintmaterialssalesresult?total=:total' component={BodyShopPaintMaterialsSalesResults} />

            </Switch>

          </Router>
         </div>
            <Footer />
        </div>
    );
}

export default withRouter(App);