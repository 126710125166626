import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function BodyShopLaborGrossSales() {
    const [sales, setSales] = useState(0);
    const [gross, setGross] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(gross) / parseInt(sales)) * 100);
    }, [gross, sales])

    function clickResult() {
        history.push(
            {
                pathname: `/bodyshoplaborgrosssalesresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        gross: numberValidation,
        sales: numberValidation
    });

    const def = <span>Body Shop Labor Gross Profit &#247; Body Shop Labor Sales</span>;

    return (
        <Formik
            initialValues={{ gross: "", sales: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center"> Body Shop Labor Gross % Sales</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="gross">
                                            <Form.Label>Total Body Shop Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="gross" value={values.gross} isInvalid={!!errors.gross}
                                                onChange={e => { handleChange(e); setGross(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.gross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="sales">
                                            <Form.Label>Total Body Shop Labor Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="sales" value={values.sales} isInvalid={!!errors.sales}
                                                onChange={e => { handleChange(e); setSales(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.sales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function BodyShopLaborGrossSalesResults() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Body Shop Labor Gross % Sales";
    const total = urlParams.get('total') + '%';
    const ratio = "70%";

    const cond = parseFloat(total) >= 70;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    BodyShopLaborGrossSales,
    BodyShopLaborGrossSalesResults
}