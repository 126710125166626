import React  from 'react';
import BackButton from '../_Shared/BackButton';




function WorkinProcess() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Work In Process</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">Work in process (WIP).
                                            <br /><br/>
                                          Guide:  2 days average cost of technician labor
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    WorkinProcess
}