import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function UsedTruckDaysSupplyInDollars() {
    const [usedInventory, setUsedInventory] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [usedSale, setUsedSale] = useState(0);
    const [months, setMonths] = useState(0);
    const [workingDays, setworkingDays] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                ((parseInt(usedInventory) / (parseInt(usedSale) - parseInt(grossProfit))) * parseInt(workingDays))
            );
        }
        else {
            setTotal(
                ((parseInt(usedInventory) / ((parseInt(usedSale) - parseInt(grossProfit)) / parseInt(months))) * parseInt(workingDays))
            );
        }
    }, [usedInventory, grossProfit, usedSale, months, workingDays])

    function clickResult() {
        history.push(
            {
                pathname: `/usedtruckdaysdupplyindollarsresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        usedInventory: numberValidation,
        grossProfit: numberValidation,
        usedSale: numberValidation,
        months: numberValidation,
        workingDays: numberValidation
    });

    const def = <span>Used-truck inventory dollar value ÷ used-truck cost of sale (average month) x number of working days per month.</span>;

    return (
        <Formik
            initialValues={{ usedInventory: "", grossProfit: "", usedSale: "", months: "", workingDays:"" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Used Truck Days Supply in Dollars</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedInventory">
                                            <Form.Label>Used Truck Inventory Dollar Value</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedInventory" value={values.usedInventory} isInvalid={!!errors.usedInventory}
                                                onChange={e => { handleChange(e); setUsedInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="grossProfit">
                                            <Form.Label>Used Truck Gross Profit without F&I</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="grossProfit" value={values.grossProfit} isInvalid={!!errors.grossProfit}
                                                onChange={e => { handleChange(e); setGrossProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.grossProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedSale">
                                            <Form.Label>Used Truck Sales $ without F&I</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedSale" value={values.usedSale} isInvalid={!!errors.usedSale}
                                                    onChange={e => { handleChange(e); setUsedSale(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedSale}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months of Sales $ Entered Above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" mb="3" controlId="workingDays">
                                            <Form.Label># Number of Working Days</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="workingDays" isInvalid={!!errors.workingDays}
                                                    value={values.workingDays} onChange={e => { handleChange(e); setworkingDays(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.workingDays}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function UsedTruckDaysSupplyInDollarsResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Used Truck Days Supply in Dollars";
    const total = urlParams.get('total') + " Days";
    const ratio = <div>60 Days<br /><h2>6 turns per year no units over 180 days old</h2></div>;

    const cond = parseFloat(total) <= 60.00; 

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    UsedTruckDaysSupplyInDollars,
    UsedTruckDaysSupplyInDollarsResult
}