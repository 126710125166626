import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function StallUtilization() {
    const [stalls, setStalls] = useState(0);
    const [technicians, setTechnicians] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(technicians) / parseInt(stalls)) * 100);
    }, [stalls, technicians])

    function clickResult() {
        history.push(
            {
                pathname: `/stallutilizationresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        stalls: numberValidation,
        technicians: numberValidation
    });

    const def = <span># of Technicians &#247; # of Stalls</span>;

    return (
        <Formik
            initialValues={{ stalls: "", technicians: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Stall Utilization</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="stalls">
                                            <Form.Label>Number of Stalls</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="stalls" value={values.stalls} isInvalid={!!errors.stalls}
                                                onChange={e => { handleChange(e); setStalls(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.stalls}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="technicians">
                                                <Form.Label>Number of Service Technicians</Form.Label>
                                                <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="technicians" value={values.technicians} isInvalid={!!errors.technicians}
                                                onChange={e => { handleChange(e); setTechnicians(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.technicians}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>


                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function StallUtilizationResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Stall Utilization";
    const total = urlParams.get('total') + '%';
    const ratio = <h5>One-Shift Service : 80% <br /> Two-Shift Service : 65% <br /> 24/7 Day Service: 45%</h5>;

    const cond = parseFloat(total) >= 45;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );   
}

export {
    StallUtilization,
    StallUtilizationResult
}