import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function CashInBank() {
    const [expenses, setExpenses] = useState(0);
    const [cash, setCash] = useState(0);
    const [contracts, setContracts] = useState(0);
    const [labour, setLabour] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    //useEffect(() => {
    //    if (parseInt(months) == ' ') {
    //        setTotal(
    //            ((parseInt(expenses) + parseInt(cash) + parseInt(contracts)) / parseInt(labour))
    //        );
    //    }
    //    else { 
    //        setTotal(
    //            (parseInt(expenses) + parseInt(cash) + parseInt(contracts)) / (parseInt(labour) / parseInt(months))
    //        )
    //    }
    //}, 

    useEffect(() => {
        setTotal(
            (parseInt(cash) + parseInt(contracts)) / ((parseInt(expenses) / parseInt(months)) +(parseInt(labour) / parseInt(months)))
        );
    }, [expenses, cash, contracts, labour, months])

    function clickResult() {
        history.push(
            {
                pathname: `/cashinbankresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        expenses: numberValidation,
        cash: numberValidation,
        contracts: numberValidation,
        labour: numberValidation,
        months: Yup.number()
            .required('Required *')
            .positive('Number must be positive')
            .typeError('Please enter a number')
            .max('12', 'Value must be less than or equal to 12')

    });

    const def = <span>Amount equal to a minimum of 30 days, total dealership expenses (average) + technician payroll. </span>;

    return (
        <Formik
            initialValues={{ expenses: "", cash: "", contracts:"", labour: "", months:"" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Cash in Bank</h2>
                                
                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="expenses">
                                            <Form.Label>Total Dealership Expenses (YTD)</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="expenses" isInvalid={!!errors.expenses}
                                                value={values.expenses} onChange={e => { handleChange(e); setExpenses(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.expenses}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="cash">
                                            <Form.Label>Total Cash</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="cash" isInvalid={!!errors.cash}
                                                value={values.cash} onChange={e => { handleChange(e); setCash(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.cash}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="contracts">
                                            <Form.Label>Contracts in Transit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="contracts" isInvalid={!!errors.contracts}
                                                value={values.contracts} onChange={e => { handleChange(e); setContracts(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.contracts}</Form.Control.Feedback>
                                                </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="labour">
                                            <Form.Label>Technician Cost of Labor (YTD)</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="labour" isInvalid={!!errors.labour}
                                                value={values.labour} onChange={e => { handleChange(e); setLabour(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.labour}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months of Expense $ Entered Above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                    value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function CashInBankResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Cash In Bank";
    const total = urlParams.get('total') + " Month(s)";
    const ratio = <div>1 Month<br /><h2>(3 Months recommended)</h2></div>;

    const cond = parseFloat(total) >= 1;


    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    CashInBank,
    CashInBankResult
}