import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function UsedTruckInventoryDaysSupply() {
    const [usedInventory, setUsedInventory] = useState(0);
    const [usedSold, setUsedSold] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            (parseInt(usedInventory) / (parseInt(usedSold) / parseInt(months))) * 30
        );
    }, [usedInventory, usedSold, months])

    function clickResult() {
        history.push(
            {
                pathname: `/usedtruckinventorydayssupplyresult?total=${parseInt(total)}`
            })
    }

    const validationSchema = Yup.object({
        usedInventory: numberValidation,
        usedSold: numberValidation,
        months: monthValidation
    });

    const def = <span>(Used Inventory Units ÷ (Used Truck Unit Sold per Month)) x 30)</span>;

    return (
        <Formik
            initialValues={{ usedInventory: "", usedSold: "", months: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Used Truck Inventory Days Supply</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedInventory">
                                            <Form.Label>Used Truck Inventory Units</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedInventory" value={values.usedInventory} isInvalid={!!errors.usedInventory}
                                                onChange={e => { handleChange(e); setUsedInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedSold">
                                            <Form.Label>Used Truck Sold</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedSold" value={values.usedSold} isInvalid={!!errors.usedSold}
                                                onChange={e => { handleChange(e); setUsedSold(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedSold}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function UsedTruckInventoryDaysSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Used Truck Inventory Days Supply";
    const total = urlParams.get('total') + " Days";
    const ratio = "60 Days";

    const cond = parseInt(total) <= 30;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    UsedTruckInventoryDaysSupply,
    UsedTruckInventoryDaysSupplyResult
}