import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NewTruckGrossSales() {
    const [newGross, setNewGross] = useState(0);
    const [newSales, setNewSales] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal((parseInt(newGross) / parseInt(newSales)) * 100);
    }, [newGross, newSales])

    function clickResult() {
        history.push(
            {
                pathname: `/newtruckgrosssalesresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        newGross: numberValidation,
        newSales: numberValidation
    });
    const def = <span>New Truck Dept Gross &#247; New Truck Dept Sales</span>;

    return (
        <Formik
            initialValues={{ newGross: "", newSales: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">New Truck Gross % Sales</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newGross">
                                            <Form.Label>New Truck Department Gross</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="newGross" value={values.newGross} isInvalid={!!errors.newGross}
                                                onChange={e => { handleChange(e); setNewGross(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newGross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newSales">
                                            <Form.Label>New Truck Department Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newSales" value={values.newSales} isInvalid={!!errors.newSales}
                                                onChange={e => { handleChange(e); setNewSales(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newSales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <div className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NewTruckGrossSalesResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "New Truck Gross % Sales";
    const total = urlParams.get('total') + " %";
    const ratio = "6%";

    //const cond = parseFloat(total) >= 11 && parseFloat(total) <= 13;
    const cond = parseFloat(total) >= 6;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    NewTruckGrossSales,
    NewTruckGrossSalesResult
}